import React from "react"
import { CloudRobot } from "../../../images/CloudSolutions/Graphics"

import "./Banner.scss"

export default function Banner() {
  return (
    <div className="cls-banner-container">
      <div className="cls-banner-wrapper">
        <h1 className="cls-banner-header">Cloud Development</h1>
        <div className="robot-container">
          <CloudRobot />
        </div>
      </div>
    </div>
  )
}
