import React from "react"

import { Link } from "gatsby"
import { Parallax } from "react-scroll-parallax"
import {
  LowerCloud,
  UpperCloud,
  ConnectedCloud,
  RightCloud,
} from "../../../images/CloudSolutions/Graphics"

import "./ParallaxSection.scss"

export default function ParallaxSection() {
  return (
    <div className="parallax-container">
      <div className="parallax-wrapper">
        <div className="parallax-text-container">
          <h2 className="parallax-header">
            Explore our expertise with cloud solutions
          </h2>
          <p className="parallax-paragraph">
            We support businesses with robust cloud applications and taking them
            to the next level. Our technical expertise is backed up by solid
            experience from complex cloud. Our strong cloud expertise will help
            you implement cloud services whether you are planning to launch a
            new product or empower the already existing one.
          </p>
          <p className="parallax-paragraph">
            OS-System’s cloud development solutions will help you to assure
            scalability and high load of your product or services. Our{" "}
            <Link
              to="/services/dedicatedteam/"
              className="parallax-paragraph-link"
            >
              dedicated development teams
            </Link>{" "}
            help companies around the globe to build modern applications based
            on the cloud services. Whether you are a startup, SMB or enterprise
            company, we can provide you with a high-quality cloud solution that
            will fit your particular business.
          </p>
        </div>
        <div className="parallax-cloud-container">
          <ConnectedCloud />
          <Parallax y={[80, -60]} className="upper-cloud-container desktop">
            <UpperCloud />
          </Parallax>
          <Parallax y={[0, 250]} className="lower-cloud-container desktop">
            <LowerCloud />
          </Parallax>
          <Parallax x={[20, 330]} className="right-cloud-container desktop">
            <RightCloud />
          </Parallax>
          <Parallax x={[0, 150]} className="upper-cloud-container mobile">
            <UpperCloud />
          </Parallax>
          <Parallax x={[0, -200]} className="lower-cloud-container mobile">
            <LowerCloud />
          </Parallax>
        </div>
      </div>
    </div>
  )
}
