import React from "react"
import { withController } from "react-scroll-parallax"

class ConnectedCloud extends React.Component {
  handleLoad = () => {
    this.props.parallaxController.update()
  }

  render() {
    return (
      <img
        src={require("./ConnectedCloud.svg")}
        className="img-connected-cloud"
        alt='Cloud with wires'
        onLoad={this.handleLoad}
      />
    )
  }
}

export default withController(ConnectedCloud)
