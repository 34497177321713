import React from "react"

import dataForServices from "../../../information/CloudSolutions/dataForServices"

import "./Services.scss"

export default function Services() {
  return (
    <div className="service-container">
      <div className="service-wrap">
        <h3 className="service-header">Our cloud services</h3>
        <div className="service-card-holder">
          {dataForServices.map(item => {
            const { id, img, header, data } = item
            return (
              <div className="service-card" key={id}>
                <div className="service-card-caption">
                  <img className="service-card-icon" src={img} />
                  <h4 className="service-card-header">{header}</h4>
                </div>
                <div className="service-card-data">{data}</div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
