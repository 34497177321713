import React from "react"
import SEO from "../../components/CommonComponents/SEO"
import Layout from "../../components/CommonComponents/Layout"
import Banner from "../../components/CloudSolutions/Banner"
import ParallaxSection from "../../components/CloudSolutions/ParallaxSection"
import Services from "../../components/CloudSolutions/Services"
import Cases from "../../components/CommonComponents/Cases"
import ContactUsExpertise from "../../components/CommonComponents/ContactUsExpertise"
import { ParallaxProvider } from "react-scroll-parallax"
import { mainUrl } from "../../js/config"
import dataForProjects from "../../information/CloudSolutions/dataForProjects.json"

const settings = {
  dots: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
  responsive: [
    {
      breakpoint: 1021,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        className: "center",
      },
    },
  ],
}

export default function CloudSolutions() {
  const sendUrl = typeof window !== "undefined" ? window?.location?.href : ""
  return (
    <div>
      <SEO
        title="Cloud development solution from OSSystem professionals"
        description="Our experience with cloud solutions backed up by many successful cloud projects. If you are in need of cloud based development, contact us now."
        canonical={`${mainUrl}/expertise/cloud-solutions/`}
      />
      <ParallaxProvider className="parallax-provider">
        <Layout showFormPoint={300}>
          <Banner />
          <ParallaxSection />
          <Services />
          <Cases
            projects={dataForProjects}
            settings={settings}
            headerText="Work Projects"
            customClass="cloud"
          />
          <ContactUsExpertise form={0} url={sendUrl} />
        </Layout>
      </ParallaxProvider>
    </div>
  )
}
