import React from "react"
import { withController } from "react-scroll-parallax"

class RightCloud extends React.Component {
  handleLoad = () => {
    this.props.parallaxController.update()
  }

  render() {
    return (
      <img
        src={require("./RightCloud.svg")}
        className="img-right-cloud"
        onLoad={this.handleLoad}
      />
    )
  }
}

export default withController(RightCloud)
