import CloudConsulting from "../../images/CloudSolutions/Icons/CloudConsulting.svg"
import CloudDevelopment from "../../images/CloudSolutions/Icons/CloudDevelopment.svg"
import CloudMigration from "../../images/CloudSolutions/Icons/CloudMigration.svg"

const dataForServices = [
  {
    id: 1,
    img: CloudConsulting,
    header: "Consulting",
    data:
      "Get consulted by a team of cloud development professionals to transform your business. We provide our customers with strategic consulting, planning, delivery and support in the field of cloud solutions. Our experts can analyse your application and define the needed capabilities for the successful cloud integration or migration.",
  },
  {
    id: 2,
    img: CloudDevelopment,
    header: "Development",
    data:
      "We build cloud solutions for our clients based on the latest technologies and methodologies. Our team can help you develop new solution, establish modern practices for a successful continuous integration setup, and establish the full continuous deployment pipeline for your project.",
  },
  {
    id: 3,
    img: CloudMigration,
    header: "Migration",
    data:
      "Our team can help you with existing high-quality solutions to migrate your already existing applications. We use the latest practices to minimize the impact on system while transferring it to the cloud.",
  },
]

export default dataForServices
