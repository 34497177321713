import React from "react"

export default function CloudRobot() {
  return (
    <div className="cloud-robot-container">
      <svg
        width="571"
        height="530"
        viewBox="0 0 571 530"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 407.931L218.146 252.447L248.64 259.819L130.016 529.57L0 407.931Z"
          fill="url(#paint0_linear)"
        />
        <rect
          width="83.6326"
          height="60.7273"
          transform="matrix(0.866025 -0.5 0.866025 0.5 59.6465 391.44)"
          fill="#888888"
        />
        <rect
          width="83.6326"
          height="60.7273"
          transform="matrix(0.866025 -0.5 0.866025 0.5 59.6465 391.439)"
          fill="#888888"
        />
        <rect
          width="61.8606"
          height="40.5935"
          transform="matrix(0.866025 0.5 -2.20305e-08 1 131.356 308.742)"
          fill="#002D96"
        />
        <rect
          width="83.6326"
          height="41.3759"
          transform="matrix(0.866025 -0.5 2.20305e-08 1 58.9766 350.559)"
          fill="#5391FA"
        />
        <rect
          width="3.3453"
          height="46.9193"
          transform="matrix(0.866025 0.5 -2.20305e-08 1 78.1475 335.521)"
          fill="#002D96"
        />
        <rect
          width="37.8095"
          height="3.3453"
          transform="matrix(0.866025 -0.5 0.866025 0.5 78.1475 335.521)"
          fill="#0035B8"
        />
        <path
          d="M112.97 318.758L110.697 316.727L113.268 311.904L116.161 313.577L112.97 318.758Z"
          fill="#000092"
        />
        <rect
          width="26.4297"
          height="3.3453"
          transform="matrix(0.866025 -0.5 0.866025 0.5 113.268 311.904)"
          fill="#0035B8"
        />
        <path
          d="M81.0439 337.199L112.966 318.768L116.158 313.574L139.084 300.338L139.084 350.602L81.0439 384.112L81.0439 337.199Z"
          fill="#1755BE"
        />
        <rect
          width="3.3453"
          height="46.9193"
          transform="matrix(0.866025 0.5 -2.20305e-08 1 91.5508 342.223)"
          fill="#0D4BB4"
        />
        <rect
          width="37.8095"
          height="3.3453"
          transform="matrix(0.866025 -0.5 0.866025 0.5 91.5508 342.223)"
          fill="#1053D6"
        />
        <path
          d="M126.373 325.46L124.101 323.429L126.671 318.606L129.564 320.279L126.373 325.46Z"
          fill="#171CB0"
        />
        <rect
          width="26.4297"
          height="3.3453"
          transform="matrix(0.866025 -0.5 0.866025 0.5 126.671 318.606)"
          fill="#1053D6"
        />
        <path
          d="M94.4473 343.901L126.369 325.471L129.561 320.277L152.487 307.04L152.487 357.305L94.4473 390.814L94.4473 343.901Z"
          fill="#3573DC"
        />
        <rect
          width="3.3453"
          height="46.9193"
          transform="matrix(0.866025 0.5 -2.20305e-08 1 104.955 348.925)"
          fill="#2B69D2"
        />
        <rect
          width="37.8095"
          height="3.3453"
          transform="matrix(0.866025 -0.5 0.866025 0.5 104.955 348.925)"
          fill="#2E71F4"
        />
        <path
          d="M139.777 332.162L137.505 330.131L140.076 325.309L142.968 326.981L139.777 332.162Z"
          fill="#353ACE"
        />
        <rect
          width="26.4297"
          height="3.3453"
          transform="matrix(0.866025 -0.5 0.866025 0.5 140.075 325.309)"
          fill="#2E71F4"
        />
        <path
          d="M107.852 350.603L139.774 332.173L142.966 326.979L165.892 313.743L165.892 364.007L107.852 397.516L107.852 350.603Z"
          fill="#5391FA"
        />
        <rect
          width="61.8606"
          height="41.3669"
          transform="matrix(0.866025 0.5 -2.20305e-08 1 58.9766 349.749)"
          fill="#3573DC"
        />
        <rect
          width="83.6326"
          height="41.3759"
          transform="matrix(0.866025 -0.5 2.20305e-08 1 112.445 380.619)"
          fill="#3573DC"
        />
        <path
          d="M571 407.931L352.854 252.447L322.36 259.819L440.984 529.57L571 407.931Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M306.276 258.479H271.091L195.695 495.39H377.986L306.276 258.479Z"
          fill="url(#paint2_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M259.664 186.727C259.665 186.621 259.666 186.516 259.666 186.41C259.666 165.749 242.916 149 222.255 149C206.474 149 192.974 158.772 187.478 172.596C184.288 171.965 180.99 171.635 177.614 171.635C149.661 171.635 127 194.296 127 222.249C127 250.203 149.661 272.863 177.614 272.863C178.499 272.863 179.378 272.841 180.251 272.796C180.938 272.841 181.631 272.863 182.33 272.863H272.87C290.232 272.863 304.307 258.788 304.307 241.426C304.307 229.268 297.405 218.721 287.305 213.491C286.674 198.705 274.567 186.89 259.664 186.727Z"
          fill="url(#paint3_radial)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M357.798 133.91C357.799 133.771 357.799 133.631 357.799 133.492C357.799 106.158 335.641 84 308.308 84C287.429 84 269.571 96.928 262.3 115.216C258.079 114.382 253.716 113.945 249.25 113.945C212.27 113.945 182.291 143.923 182.291 180.904C182.291 217.885 212.27 247.863 249.25 247.863C250.421 247.863 251.584 247.833 252.74 247.774C253.649 247.833 254.565 247.863 255.489 247.863H375.267C398.236 247.863 416.857 229.243 416.857 206.274C416.857 190.189 407.726 176.237 394.364 169.318C393.53 149.757 377.513 134.126 357.798 133.91Z"
          fill="url(#paint4_radial)"
        />
        <path
          d="M258.586 95.116C266.712 100.579 269.418 111.35 264.632 119.174C259.846 126.998 249.379 128.912 241.253 123.449C233.128 117.986 230.421 107.215 235.207 99.391C239.994 91.567 250.461 89.653 258.586 95.116Z"
          fill="url(#paint5_radial)"
        />
        <path
          d="M225.877 111.163C219.818 100.576 209.23 55.7387 218.929 49.6673C228.523 43.7207 254.881 78.8149 260.956 89.6479C267.016 100.235 264.21 113.806 254.388 119.757C244.673 125.583 231.937 121.751 225.877 111.163Z"
          fill="url(#paint6_radial)"
        />
        <path
          d="M345.506 132.809C345.506 173.685 321.865 221.538 292.793 221.538C263.721 221.538 240.08 173.685 240.08 132.809C240.08 91.9336 263.721 79.7695 292.793 79.7695C321.865 79.7695 345.506 92.0432 345.506 132.809Z"
          fill="url(#paint7_radial)"
        />
        <path
          d="M292.686 123.056C272.807 123.056 256.692 113.489 256.692 101.687C256.692 89.8847 272.807 80.3174 292.686 80.3174C312.565 80.3174 328.68 89.8847 328.68 101.687C328.68 113.489 312.565 123.056 292.686 123.056Z"
          fill="url(#paint8_radial)"
        />
        <path
          d="M267.235 92.2622C281.291 83.9337 304.08 83.9337 318.137 92.2622C323.994 95.6594 327.402 100.043 328.36 104.536C329.745 98.1799 326.443 91.4951 318.137 86.5638C304.08 78.2353 281.291 78.2353 267.235 86.5638C258.928 91.4951 255.627 98.1799 257.011 104.536C258.076 100.043 261.378 95.769 267.235 92.2622Z"
          fill="#F77228"
        />
        <path
          d="M292.793 110.92C263.093 110.92 239.016 86.1431 239.016 55.5792C239.016 25.0152 263.093 0.238281 292.793 0.238281C322.494 0.238281 346.571 25.0152 346.571 55.5792C346.571 86.1431 322.494 110.92 292.793 110.92Z"
          fill="url(#paint9_radial)"
        />
        <path
          d="M296.224 98.4137C282.592 101.654 262.471 93.5412 251.377 80.2295C240.282 66.9179 242.377 53.5256 256.009 50.2856C269.641 47.0457 289.762 55.1582 300.857 68.4698C311.951 81.7814 309.856 95.1738 296.224 98.4137Z"
          fill="url(#paint10_radial)"
        />
        <path
          d="M266.096 81.2819C262.3 80.6256 258.019 76.3807 256.564 71.7942C255.109 67.2078 257.014 64.0322 260.81 64.6885C264.606 65.3447 268.887 69.5897 270.342 74.1761C271.796 78.7625 269.892 81.9381 266.096 81.2819Z"
          fill="url(#paint11_radial)"
        />
        <path
          d="M270.74 77.9327C269.971 78.4453 268.949 78.6376 267.684 78.4189C263.888 77.7626 259.607 73.5177 258.152 68.9313C257.721 67.5656 257.572 66.2486 257.685 65.1613C256.068 66.2652 255.532 68.6628 256.496 71.7809C257.951 76.3673 262.232 80.6122 266.027 81.2685C268.699 81.7303 270.415 80.3668 270.74 77.9327Z"
          fill="#F77228"
        />
        <path
          d="M289.921 91.6051C286.125 90.9489 281.844 86.7039 280.389 82.1175C278.934 77.5311 280.839 74.3555 284.635 75.0117C288.431 75.668 292.712 79.9129 294.167 84.4993C295.622 89.0858 293.717 92.2613 289.921 91.6051Z"
          fill="url(#paint12_radial)"
        />
        <path
          d="M294.564 88.2559C293.795 88.7686 292.773 88.9609 291.508 88.7421C287.712 88.0859 283.431 83.8409 281.976 79.2545C281.546 77.8889 281.397 76.5718 281.51 75.4846C279.892 76.5884 279.356 78.9861 280.32 82.1041C281.775 86.6905 286.056 90.9355 289.852 91.5917C292.523 92.0535 294.239 90.69 294.564 88.2559Z"
          fill="#F77228"
        />
        <path
          d="M366.548 129.706C372.5 119.166 382.595 74.3127 372.802 68.0779C363.117 61.9699 337.102 96.8152 331.137 107.601C325.186 118.141 328.154 131.804 338.069 137.919C347.875 143.908 360.597 140.246 366.548 129.706Z"
          fill="url(#paint13_radial)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M279.059 159.543C279.058 159.404 279.057 159.264 279.057 159.125C279.057 131.791 301.215 109.633 328.549 109.633C349.427 109.633 367.286 122.561 374.557 140.848C378.777 140.015 383.141 139.577 387.606 139.577C424.587 139.577 454.565 169.556 454.565 206.537C454.565 243.517 424.587 273.496 387.606 273.496C384.833 273.496 382.1 273.328 379.415 273H245.5C222.58 273 204 254.42 204 231.5C204 209.412 221.257 191.354 243.024 190.073C246.066 172.853 261.013 159.741 279.059 159.543Z"
          fill="url(#paint14_radial)"
        />
        <path
          opacity="0.5"
          d="M452.042 288.638C443.808 288.638 437.133 291.496 437.133 295.022V326.945C426.245 356.129 410.155 383.697 410.155 402.851C410.155 413.43 428.908 422.005 452.042 422.005C475.176 422.005 493.929 413.43 493.929 402.851C493.929 383.697 479.054 356.573 466.951 326.945V295.022C466.951 291.496 460.276 288.638 452.042 288.638Z"
          fill="#1786EC"
        />
        <path
          opacity="0.5"
          d="M452.042 339.571C463.348 339.571 472.512 343.72 472.512 348.838L485.217 403.014C485.217 411.281 470.364 417.983 452.042 417.983C433.721 417.983 418.868 411.281 418.868 403.014L431.573 348.838C431.573 343.72 440.737 339.571 452.042 339.571Z"
          fill="url(#paint15_linear)"
        />
        <path
          opacity="0.5"
          d="M485.217 402.904C485.217 411.232 470.364 417.984 452.042 417.984C433.721 417.984 418.868 411.232 418.868 402.904C418.868 394.576 433.721 387.825 452.042 387.825C470.364 387.825 485.217 394.576 485.217 402.904Z"
          fill="#204CBB"
        />
        <path
          opacity="0.5"
          d="M472.483 348.954C472.483 354.136 463.331 358.337 452.042 358.337C440.753 358.337 431.602 354.136 431.602 348.954C431.602 343.772 440.753 339.571 452.042 339.571C463.331 339.571 472.483 343.772 472.483 348.954Z"
          fill="#204CBB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M465.577 294.783C465.577 294.783 465.575 294.788 465.57 294.799C465.574 294.788 465.576 294.783 465.577 294.783ZM465.415 295.004C465.391 294.978 465.364 294.949 465.334 294.917C464.941 294.514 464.168 293.959 462.884 293.402C460.328 292.293 456.498 291.501 452.042 291.501C447.586 291.501 443.757 292.293 441.201 293.402C439.917 293.959 439.144 294.514 438.751 294.917C438.72 294.949 438.693 294.978 438.67 295.004C438.693 295.031 438.72 295.059 438.751 295.091C439.144 295.494 439.917 296.049 441.201 296.606C443.757 297.715 447.586 298.507 452.042 298.507C456.498 298.507 460.328 297.715 462.884 296.606C464.168 296.049 464.941 295.494 465.334 295.091C465.364 295.059 465.391 295.031 465.415 295.004ZM438.508 294.783C438.508 294.783 438.511 294.788 438.515 294.799C438.509 294.788 438.507 294.783 438.508 294.783ZM438.508 295.225C438.507 295.225 438.509 295.22 438.515 295.209C438.511 295.22 438.508 295.225 438.508 295.225ZM465.57 295.209C465.575 295.22 465.577 295.225 465.577 295.225C465.576 295.225 465.574 295.22 465.57 295.209ZM452.042 302.711C461.851 302.711 469.802 299.261 469.802 295.004C469.802 290.747 461.851 287.297 452.042 287.297C442.234 287.297 434.282 290.747 434.282 295.004C434.282 299.261 442.234 302.711 452.042 302.711Z"
          fill="#204CBB"
        />
        <path d="M444.335 367.05H461.09V368.39H444.335V367.05Z" fill="white" />
        <path
          d="M438.303 389.166H467.121V391.177H438.303V389.166Z"
          fill="white"
        />
        <path
          d="M441.654 377.772H464.441V379.783H441.654V377.772Z"
          fill="white"
        />
        <path
          opacity="0.75"
          d="M228.534 429.677L244.007 405.788L266.266 397.373L286.353 372.671L303.055 382.852L303.055 399.953L228.534 442.978L228.534 429.677Z"
          fill="#505050"
          stroke="white"
          strokeOpacity="0.8"
          strokeWidth="2"
        />
        <path
          opacity="0.8"
          d="M250.25 440.533L265.295 409.588L276.579 404.974L306.905 379.05L324.771 393.708L324.771 410.81L250.25 453.834L250.25 440.533Z"
          fill="#3573DC"
          stroke="white"
          strokeOpacity="0.8"
          strokeWidth="2"
        />
        <path
          opacity="0.8"
          d="M271.966 456.957L296.414 420.583L298.295 432.255L328.621 384.616L346.487 410.132L346.487 421.804L271.966 464.829L271.966 456.957Z"
          fill="white"
          stroke="white"
          strokeOpacity="0.8"
          strokeWidth="2"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="248.64"
            y1="239.714"
            x2="69.3644"
            y2="454.844"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#ACC4FA" stopOpacity="0.5" />
            <stop offset="1" stopColor="#ACC4FA" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="322.36"
            y1="239.714"
            x2="501.636"
            y2="454.844"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#ACC4FA" stopOpacity="0.5" />
            <stop offset="1" stopColor="#ACC4FA" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="286.841"
            y1="241.389"
            x2="253.498"
            y2="461.179"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#ACC4FA" stopOpacity="0.5" />
            <stop offset="1" stopColor="#ACC4FA" stopOpacity="0" />
          </linearGradient>
          <radialGradient
            id="paint3_radial"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(160.321 196.302) rotate(180) scale(149.946 93.8453)"
          >
            <stop stopColor="#BFD8F6" />
            <stop offset="1" stopColor="white" />
          </radialGradient>
          <radialGradient
            id="paint4_radial"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(226.373 146.577) rotate(180) scale(198.369 124.151)"
          >
            <stop stopColor="#BFD8F6" />
            <stop offset="1" stopColor="white" />
          </radialGradient>
          <radialGradient
            id="paint5_radial"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(238.69 106.455) rotate(-146.086) scale(29.9855 25.1418)"
          >
            <stop stopColor="#BFD8F6" />
            <stop offset="1" stopColor="#204BBB" />
          </radialGradient>
          <radialGradient
            id="paint6_radial"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(234.59 109.688) rotate(130.523) scale(36.2968 44.7757)"
          >
            <stop stopColor="#BFD8F6" />
            <stop offset="1" stopColor="#204BBB" />
          </radialGradient>
          <radialGradient
            id="paint7_radial"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(325.693 133.909) scale(89.1573 107.411)"
          >
            <stop stopColor="#BFD8F6" />
            <stop offset="1" stopColor="#204BBB" />
          </radialGradient>
          <radialGradient
            id="paint8_radial"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(290.663 125.796) rotate(-86.9098) scale(29.6313 42.9308)"
          >
            <stop stopColor="#FFA45A" />
            <stop offset="0.254754" stopColor="#FFA45A" />
            <stop offset="1" stopColor="#F75A28" />
          </radialGradient>
          <radialGradient
            id="paint9_radial"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(326.358 42.5062) scale(90.9585 83.8582)"
          >
            <stop stopColor="#BFD8F6" />
            <stop offset="1" stopColor="#204BBB" />
          </radialGradient>
          <radialGradient
            id="paint10_radial"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(281.945 51.0098) rotate(92.2852) scale(40.3085 23.0317)"
          >
            <stop stopColor="#76695C" />
            <stop offset="0.254754" stopColor="#614C41" />
            <stop offset="1" stopColor="#3E251F" />
          </radialGradient>
          <radialGradient
            id="paint11_radial"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(267.326 67.6872) rotate(114.272) scale(11.0349 6.3674)"
          >
            <stop stopColor="#FFA45A" />
            <stop offset="0.254754" stopColor="#FFA45A" />
            <stop offset="1" stopColor="#F75A28" />
          </radialGradient>
          <radialGradient
            id="paint12_radial"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(291.151 78.0105) rotate(114.272) scale(11.0349 6.3674)"
          >
            <stop stopColor="#FFA45A" />
            <stop offset="0.254754" stopColor="#FFA45A" />
            <stop offset="1" stopColor="#F75A28" />
          </radialGradient>
          <radialGradient
            id="paint13_radial"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(357.798 128.099) rotate(49.4843) scale(36.8792 44.3331)"
          >
            <stop stopColor="#BFD8F6" />
            <stop offset="1" stopColor="#204BBB" />
          </radialGradient>
          <radialGradient
            id="paint14_radial"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(410.483 172.21) scale(198.369 124.151)"
          >
            <stop stopColor="#BFD8F6" />
            <stop offset="1" stopColor="white" />
          </radialGradient>
          <linearGradient
            id="paint15_linear"
            x1="487.447"
            y1="369.516"
            x2="375.227"
            y2="369.516"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#204BBB" stopOpacity="0.5" />
            <stop offset="1" stopColor="#BFD8F6" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}
