import React from "react"
import { withController } from "react-scroll-parallax"

class UpperCloud extends React.Component {
  handleLoad = () => {
    this.props.parallaxController.update()
  }

  render() {
    return (
      <img
        src={require("./UpperCloud.svg")}
        className="img-upper-cloud"
        onLoad={this.handleLoad}
      />
    )
  }
}

export default withController(UpperCloud)
